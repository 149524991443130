<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false">
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1"></i>
			<span class="download">处理会话</span>
		</div>
		<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="医师编号">
				<el-input v-model.trim="ruleForm.id" disabled></el-input>
			</el-form-item>
			<el-form-item label="医师信息">
				<el-input v-model.trim="ruleForm.medicalWorkerTitle" disabled></el-input>
			</el-form-item>
			<el-form-item label="用户" class="attractionAddress">
				<el-input v-model.trim="ruleForm.creatorTitle" disabled style="width: calc(100% - 74px)"></el-input>
				<el-button @click="showDetails">查看详情</el-button>
			</el-form-item>
			<el-form-item label="主题内容">
				<el-input v-model.trim="ruleForm.description" disabled type="textarea" resize="none" autosize></el-input>
			</el-form-item>
			<el-form-item label="图片">
				<div class="imgs">
					<img v-for="(item, index) in images" :key="index" :src="item.url" />
				</div>
			</el-form-item>
			<el-form-item label="会话区" prop="messagesList">
				<el-input v-model.trim="ruleForm.messagesList" type="textarea" disabled autosize></el-input>
			</el-form-item>
			<el-form-item label="回复" v-if="showReturn">
				<el-input v-model.trim="ruleForm.responents">
					<el-button slot="append" @click="sendMsg">发送</el-button>
				</el-input>
			</el-form-item>
		</el-form>
		<el-dialog width="500px" title="" :visible.sync="innerVisible" center append-to-body :close-on-click-modal="false">
			<div slot="title" class="postion">
				<i class="iconfont icon-bianji1"></i>
				<span class="download">用户详情</span>
			</div>
			<el-form :model="userInfo" ref="userInfo" label-width="100px" class="demo-ruleForm" label-position="top">
				<el-form-item label="用户名">
					<el-input v-model.trim="userInfo.name" disabled></el-input>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-input v-model.trim="userInfo.phone" disabled></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="innerVisible = false" type="primary">确 定</el-button>
			</span>
		</el-dialog>
		<span slot="footer" class="dialog-footer">
			<el-button @click="createdVisible = false" type="primary">关 闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			createdVisible: false,
			images: [],
			ruleForm: {},
			saveLoading: false,
			nim: null,
			userInfo: {},
			innerVisible: false,
			resData: {},
			link: '',
			showReturn: true,
		};
	},
	watch: {},
	created() {},
	methods: {
		init({ row, link, state }) {
			this.createdVisible = true;
			this.link = link;
			this.showReturn = state;
			this.getDetail(link);
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取登录Im信息
		// getIMinfo() {
		// 	let { accId, accToken } = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
		// 	console.log(accId, accToken);
		// 	// debugger;
		// 	if (!accId || !accToken) return this.$message.error('IM信息获取失败，请重新登录');
		// 	this.nim = this.NIM.getInstance({
		// 		// 初始化SDK
		// 		//   debug: true,
		// 		appKey: AppKeyIm, // appkey
		// 		account: accId, // 当前登录账号（需要自行去后台管理添加如下图，也可以调用接口注册）
		// 		token: accToken, // 当前登录密码（这是静态token，动态的即自动登录，可以自行查看官网文档）
		// 		onconnect: this.onConnect, // 若登录成功，可通过该回调获取登录信息
		// 		onerror: this.onError, // 出现错误的回调，这里的“错误”通常为数据库错误，也可能是连接错误
		// 		onwillreconnect: this.onWillReconnect, // NIM SDK 与云信服务端的长连接已断开，而且正在重连时触发的回调函数
		// 		ondisconnect: this.onDisconnect, // NIM SDK 与云信服务端的长连接断开后的回调函数
		// 		// 会话
		// 		onsessions: this.onSessions, // 初始化同步时，用于获取会话列表的回调函数
		// 		autoMarkRead: true, // 开启自动标记消息已读
		// 		syncSessionUnread: true, // 同步会话的未读数,
		// 		onupdatesession: this.onUpdateSession, // 批量更新会话的回调函数。触发条件包括收发消息、清理会话未读数、撤回消息等
		// 		// 消息
		// 		onroamingmsgs: this.onRoamingMsgs, // 初始化同步时，用于接收漫游消息的回调函数
		// 		onofflinemsgs: this.onOfflineMsgs, // 初始化同步时，用于接收离线消息的回调函数
		// 		onmsg: this.onMsg, // 用户在线或多端同步时，用于接收消息的回调函数（实时消息）
		// 		// 同步完成
		// 		onsyncdone: this.onSyncDone, // 初始化同步完成的回调函数。推荐在初始化同步完成后再调用 SDK 实例的接口
		// 	});
		// },
		// onConnect(data) {
		// 	console.log('连接成功', data);
		// },
		// onError(data) {
		// 	console.log('连接失败', data);
		// },
		// onWillReconnect(data) {
		// 	console.log('即将重连', data);
		// },
		// onDisconnect(error) {
		// 	console.log('连接断开', error);
		// },
		// onSessions(sessions) {
		// 	console.log('收到会话列表', sessions);
		// },
		// onUpdateSession(session) {
		// 	console.log('会话更新了', session);
		// },
		// onRoamingMsgs(obj) {
		// 	console.log('漫游消息', obj);
		// },
		// onOfflineMsgs(obj) {
		// 	console.log('离线消息', obj);
		// },
		// onMsg(msg) {
		// 	console.log('收到消息', msg);
		// },
		// onSyncDone() {
		// 	console.log('同步完成');
		// },
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						let data = res.data.collection[0] || {};
						this.resData = data;
						this.ruleForm = {
							...data,
							medicalWorkerTitle: data.medicalWorker?.title || '',
							creatorTitle: data.creator?.title || '',
							messagesList:
								data.chatMessages
									?.map((v) => {
										if (v.creatorId == data.creator.id) {
											return `患者：${v.message}`;
										} else {
											return `医师：${v.message}`;
										}
									})
									.join('\n') || '',
						};
						this.images =
							data.attachments?.map((v) => {
								return {
									url: v.link,
									id: v.id,
								};
							}) || [];
					}
				})
				.catch((e) => {});
		},
		sendMsg() {
			if (!this.ruleForm.responents || this.ruleForm.responents == '') return this.$message.error('请输入回复内容');
			let data = {
				message: this.ruleForm.responents,
				messageType: 'T',
				photoInquiryId: this.resData.id,
			};
			this.$http
				.post(this.api['PhotoInquiryMessageController#create'].href, data)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('发送成功');
						this.getDetail(this.link);
					}
				})
				.catch((e) => {});
			// this.nim.sendText({
			// 	scene: 'p2p', // p2p即单聊一对一，如需发送群消息, 请将scene的值替换为team, 将to的值替换为群 ID（teamId）。如需发送超大群消息，请将scene的值替换为superTeam，将to替换为超大群的 ID（teamId）。
			// 	to: 'account', // account会话列表中获得，表示要接收这条信息的人
			// 	text: 'hello', // 需要发送的文本
			// 	done: (error, msg) => {
			// 		// 发送后的回调函数
			// 		console.log(error);
			// 		console.log(msg);
			// 		console.log('发送' + msg.scene + ' ' + msg.type + '消息' + (!error ? '成功' : '失败') + ', id=' + msg.idClient);
			// 		// pushMsg(msg);
			// 	},
			// });
		},
		// 查看用户详情
		showDetails() {
			this.userInfo = {
				name: this.ruleForm.creator?.title || '',
				phone: this.ruleForm.creator?.code || '',
			};
			this.innerVisible = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.el-textarea__inner {
		min-height: 64px !important;
		line-height: 18px !important;
		padding: 5px 12px !important;
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border: none;
		border-bottom: 1px solid #1db9b1;
		color: #33a7a1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
</style>